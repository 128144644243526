#price_adjustment_editor_popup_wrapper:not(.inline) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: #00000091;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-in-out;
}

#price_adjustment_editor_popup_wrapper:not(.loaded){
    opacity: 0;
    visibility: hidden;
}

#price_adjustment_editor_popup_wrapper.inline {
    opacity: 1;
    visibility: visible;
    background-color: #f5f6fa;
    border-radius: 4px;
}

#price_adjustment_editor_popup {
    background: #fff;
    border-radius: 4px;
    padding: 25px;
    width: 660px;
    position: relative;
}
#price_adjustment_editor_popup_wrapper.inline #price_adjustment_editor_popup {
    background: transparent;
}
#price_adjustment_editor_popup_wrapper.inline #price_adjustment_editor_popup {
    width: 100%;
    padding: 0px;
}
#price_adjustment_editor_popup .close_price_adjustment_editor_popup {
    position: absolute;
    right: -10px;
    top: -10px;
    background: #000;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
}