body {
    background: #f2f2f2 !important;
    font-size: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    margin: 0;
}

.hero-section {
    color: white;
    text-align: center;
    display: flex;
    align-items: end;

}

.contact_hero {
    width: 800px;
    font-size: 27px;
    line-height: 27px;
    font-weight: 500;
    color: #ffff;
}

.breadcrumbs {
    font-size: 18px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.breadcrumbs a {
    text-decoration: none;
    color: #A21D0B;
    font-size: 14.19px;
    line-height: 22.3px;
    font-weight: 400;
    font-family: 'Open Sans';
}

.breadcrumbs a:hover {
    text-decoration: none;
    color: #A21D0B;

}

.main-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
}

.heading-orders {
    font-size: 24.33px;
    font-family: Inter;
    font-weight: 800;
    line-height: 36.49px;
}

.menu {
    display: flex;
    gap: 20px;
}

.menu-item {
    margin-right: 10px;
    cursor: pointer;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 20.27px;
    position: relative;
}

.main-order .menu-item {
    padding: 8px 22px;
}

.menu-item::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #A21D0B;
    position: absolute;
    bottom: -3px;
    left: 0;
    transition: width 0.3s;
}

.menu-item:hover::after {
    width: 100%;
}


.menu-item:last-child {
    margin-right: 0;
}

.search {
    display: flex;
    align-items: center;
}

.search input[type="text"] {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 400px;
    margin-right: 10px;
    height: 40px;
}

.search button {
    padding: 10px 15px;
    background-color: #090F25;
    color: #fff;
    border: none;
    font-weight: 500;
    text-align: center;
    line-height: 20.27px;
    font-size: 14.19px;
    font-family: Inter;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
}

.search button:hover {
    background-color: #0056b3;
}

.month-dropdown {
    padding: 5.76px 8.32px 5.76px 8.32px;
    border-radius: 6px;
    box-shadow: 0px 0.64px 1.28px 0px #0000000D;
    border: 0.64px solid #E4E5E7;
}

select:focus {
    outline: none;
}

.dropdown-label {
    font-size: 12px;
    font-family: 'Roboto';
    font-size: 14.19px;
    line-height: 20.27px;
    font-weight: 500;
    margin-right: 10px;
    color: #525357;
}

.dropdown-div {
    margin-top: 30px;
}

.order-summary {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 10px 10px 12.16px 12.16px;
    justify-content: space-between;
    border: 1px solid #E4E5E7;
    box-shadow: 0px 1.01px 2.03px 0px #10182814;
}

.order-header {
    padding: 20px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
}

.order-left {
    display: flex;
    gap: 51px;
}

.orderid-title {
    font-size: 12.16px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 20.27px;
    color: #525357;
}

.link-order {
    font-size: 14.19px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 20.27px;
    color: #A21D0B;
    text-decoration: none;

}

.order-header,
.order-content {

    padding-bottom: 20px;
    margin-bottom: 20px;
}

.order-header>div,
.item-details {
    margin-bottom: 10px;
}

.order-header span,
.order-content span {
    display: block;
}

.order-date-title {
    font-size: 12.16px;
    font-weight: 500;
    font-family: 'Roboto';
    line-height: 20.27px;
    color: #525357;
}

span.hide-show {
    display: none;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 35px;
}

.order-date-dec {
    font-family: 'Roboto';
    font-size: 14.19px;
    line-height: 20.27px;
    font-weight: 500;
    color: #525357;
}

.order-content {
    display: flex;
    padding: 20px;
    justify-content: space-between;
}

.header-content-right {
    display: flex;
    align-items: center;
}

.item {
    display: flex;
    padding-top: 20px;
}

.item img {
    max-width: 100px;
    margin-right: 20px;
}

.item-details2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item-details-show {
    display: none;
}

.buy-btn {
    padding: 6.08px 33.16px 6.08px 33.16px;
    border-radius: 6.08px;
    background: #A21D0B;
    height: 33.16px;
    font-size: 12.16px;
    font-family: 'Inter';
    line-height: 20.27px;
    font-weight: 500;
    color: white;
    border: 1px solid #A21D0B;
}

.view-item {
    padding: 6.08px 33.16px 6.08px 33.16px;
    border-radius: 6.08px;
    background: transparent;
    height: 33.16px;
    font-size: 12.16px;
    font-family: 'Inter';
    line-height: 20.27px;
    font-weight: 500;
    color: #525357;
    border: 1px solid #525357;
}

.left-content-heading {
    font-family: 'Roboto';
    color: #525357;
    font-style: normal;
    font-weight: 600;
    font-size: 18.2442px;
    line-height: 20px;
    margin-bottom: 20px;
}

.product-name {
    font-family: 'Roboto';
    font-size: 14.19px;
    line-height: 20.27px;
    color: #525357;
    font-weight: 700;
}

@media only screen and (min-width: 767px) {
    .order-search {
        text-align: center;
    }

    .menu {
        display: none;
    }

    .search input[type="text"] {
        width: 800px;
    }

    .dropdown-div {
        text-align: center;
    }

    .order-header {
        display: none;
    }

    .product-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    span.hide-show {
        display: block;
    }

    .item-details-show {
        display: block;

    }

    .item-details2 {
        display: none;
    }

    .left-content-heading {
        display: none;
    }

    .order-summary {
        margin-top: 0px;
    }
}

@media only screen and (min-width: 1040px) {
    .order-search {
        text-align: start;
    }

    .menu {
        display: flex;
        gap: 20px;
    }

    .search input[type="text"] {
        width: 400px;
    }

    .dropdown-div {
        text-align: start;
    }

    .order-header {
        display: flex;
    }

    .product-details {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }

    span.hide-show {
        display: none;
    }

    .item-details-show {
        display: none;
    }

    .item-details2 {
        display: flex;
    }

    .left-content-heading {
        display: block;
    }

    .order-summary {
        margin-top: 30px;
    }
}

.order_items {
    list-style: none;
}

@media(max-width:768px) {
    .order-date {
        width: auto !important;
    }

    .order-left {
        gap: 30px !important;
    }

    .order-id {
        text-align: left !important;
    }

    .product-details ul li {
        font-size: 14px;
        line-height: 22px;
    }

    .item img {
        height: auto;
        object-fit: contain;
    }
}
.maiin-div .order-summary .order-header {
    padding: 20px !important;
}