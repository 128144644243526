body {
    background: #f2f2f2 !important;
    font-size: 100%;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    margin: 0;
}

.left-side-detail {
    margin-bottom: 25px;
}

.form-group.inline {
    display: inline-block;
    margin-right: 10px;
    width: 280px;
}

label {
    display: block;
    margin-bottom: 5px;
}


.btn-form {
    margin-top: 15px;
}

.select-option {
    border: 1px solid #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .08);
    height: 40px;
}

/* mobile responsive */
@media only screen and (min-width: 767px) {

    .container {
        padding-left: 0px;
    }

    .form-group.inline,
    .select-option {
        width: 100%;
    }
}

@media only screen and (min-width: 1040px) {
    .container {
        padding-left: 15px;
    }

    .form-group.inline {
        width: 280px;
    }

}