body {
    background: #f2f2f2 !important;
    font-size: 100%;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    margin: 0;
}

.left-side-detail {
    margin-bottom: 25px;
}

.form-group.inline {
    display: inline-block;
    margin-right: 10px;
    width: 280px;
}

label {
    display: block;
    margin-bottom: 5px;
}

.btn-form {
    margin-top: 15px;
}

.select-option {
    border: 1px solid #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, .08);
    height: 40px;
}

/* mobile responsive */
@media only screen and (min-width: 767px) {

    .container {
        padding-left: 0px;
    }

    .form-group.inline,
    .select-option {
        width: 100%;
    }
}

@media only screen and (min-width: 1040px) {

    .container {
        padding-left: 15px;
    }

    .form-group.inline {
        width: 280px;
    }

}

.grouped-form-btns input[type="submit"] {
    background: #a21d0b;
    border-radius: 6px;
    padding: 10px 25px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 0;
    height: auto;
    outline: none;
    border-color: rgba(0, 0, 0, .05);
    position: relative;
    display: inline-block;
    letter-spacing: .03em;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border: 1px solid transparent;
    vertical-align: middle;
    margin-top: 0;
    text-shadow: none;
    max-width: 100%;
    -webkit-transition: border .3s, background .3s, opacity .3s, color .3s, -webkit-transform .3s, -webkit-box-shadow .3s;
    transition: border .3s, background .3s, opacity .3s, color .3s, -webkit-transform .3s, -webkit-box-shadow .3s;
    -o-transition: transform .3s, border .3s, background .3s, box-shadow .3s, opacity .3s, color .3s;
    transition: transform .3s, border .3s, background .3s, box-shadow .3s, opacity .3s, color .3s;
    transition: transform .3s, border .3s, background .3s, box-shadow .3s, opacity .3s, color .3s, -webkit-transform .3s, -webkit-box-shadow .3s;
    text-rendering: optimizeLegibility;
}

.grouped-form-btns input[type="submit"]:hover {
    background: #82180B;
}

.grouped-form-btns .cancel-btn {
    border: 1px solid #525357;
    border-radius: 6px;
    color: #525357;
    padding: 10px 35px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    height: auto;
    display: inline-block;
}