@import '../../../node_modules/react-modal-video/scss/modal-video.scss';
.card_upper.small {
    min-height: 262px;
}
.card.sell {
    box-shadow: none;
}
.watch_video {
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-color: #f06025;
    border: 1px solid #f03d25;
    box-sizing: border-box;
    color: #fff;
    height: 60px;
    font-size: 18px;
    font-weight: 800;
    margin-top: 1em;
    display: inline-block;
    margin-bottom: 1em;
    letter-spacing: .03em;
    cursor: pointer;
    min-height: 2.5em;
    padding: 0 1.2em;
    max-width: 100%;
    text-rendering: optimizeLegibility;
    border-radius: 99px;
    line-height: 1.6;
    font-family: Roboto, sans-serif;
    outline: none;
    min-width: 169px;
}
.watch_video:hover {
    background-color: #C04D1E;
}
.video_popup_image_wrapper {
    position: relative;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
}
.video_popup_image_cover {
    padding-top: 416px;
    position: relative;
    height: auto;
    overflow: hidden;
    background-position: 50% 50%;
    background-size: cover;
}

.video_popup_image_cover img {
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    top: 0;
    position: absolute;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    -o-object-fit: cover;
    object-fit: cover;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 5px;
}
.content-area.wide {
    width: 670px;
    max-width: 100%;
}
.video_popup_action_wrapper {
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    bottom: auto !important;
    z-index: 16;
    left: 0;
    right: 0;
}
.video_popup_wrapper {
    margin-bottom: 80px;
    margin-top: 40px;
}
.modal-video-inner {
    min-width: 990px;
}
.sell_card_box {
    border-radius: 20px;
    padding: 20px;
    background: linear-gradient(180deg, rgba(255, 204, 73, 1) 0%, rgba(254, 236, 233, 1) 100%);
    min-height: 287px;
    position: relative;
    padding-top: 40px;
}

.sell_card_box h5 {
    line-height: 1.2;
    letter-spacing: .05em;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-style: italic;
    font-weight: 900;
    text-align: left;
    color: #000;
    text-rendering: optimizeSpeed;
}

.sell_card_box p {
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #777;
    line-height: 1.6;
}

.letstalk {
    border-radius: 99px;
    background: #fff;
    border: 1px solid #f03d25;
    box-sizing: border-box;
    color: #f03d25;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    font-weight: 800;
    display: block;
    text-align: center;
    min-height: 2.5em;
    padding: 0 1.2em;
    -webkit-transition: border .3s, background .3s, opacity .3s, color .3s, -webkit-transform .3s, -webkit-box-shadow .3s;
    transition: border .3s, background .3s, opacity .3s, color .3s, -webkit-transform .3s, -webkit-box-shadow .3s;
    -o-transition: transform .3s, border .3s, background .3s, box-shadow .3s, opacity .3s, color .3s;
    transition: transform .3s, border .3s, background .3s, box-shadow .3s, opacity .3s, color .3s;
    transition: transform .3s, border .3s, background .3s, box-shadow .3s, opacity .3s, color .3s, -webkit-transform .3s, -webkit-box-shadow .3s;
    text-rendering: optimizeLegibility;
}

.letstalk:hover {
    color: #fff;
    background: #f03d25;
}

.sell_card_section .left h3 {
    letter-spacing: .05em;
    line-height: 1.2;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    font-style: italic;
    font-weight: 900;
    text-align: left;
    color: #000;
}

.sell_card_section .left h4 {
    font-family: Roboto, sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 26px;
    color: #090f25;
}

.sell_card_section .left p {
    font-weight: 400;
    font-family: Roboto, sans-serif;
    line-height: 1.6;
    color: #777;
}