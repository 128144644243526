body {
    background: #f2f2f2 !important;
    font-size: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin: 0;
}

.main-containerr {
    background: #090f25;
    padding: 40px;
}

.main-inner {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.left-account {
    flex: 1;
    flex: 0 0 40%;
    padding-right: 20px;
}

.account-login-inner {
    background: #fff;
    border-radius: 12px;
    padding: 24px;
    position: relative;
}

.right-account {
    flex: 1;
    flex: 0 0 60%;
}

.img-login {
    max-width: 165px;
    display: block;
    margin: auto auto 20px;
}

.login-image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 60px;
}

.account-login-title {
    text-align: center;
    text-transform: capitalize;
    color: #171717;
    font-style: normal;
    font-weight: 800 !important;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 35px;
}

.image-row img {
    width: 33.33%;
    margin-right: 10px;
}

.image-row img:last-child {
    margin-right: 0;
}
.form-input {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
    border-radius: 6px;
    height: auto;
    width: 100%;
    outline: none;
}

.forgot-password {
    display: inline-block;
    margin-bottom: 10px;
    text-decoration: none;
    color: rgb(119, 119, 119);
}

.checkbox-container {
    display: flex;
    align-items: center;
}

input#remember-me {
    margin-right: 5px;
}

.login-btn {
    background: #a21d0b !important;
    border-radius: 6px !important;
    border-color: #a21d0b !important;
    padding: 10px 20px;
    height: auto;
    font-style: normal;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: block;
    border: 1px solid;
    width: 100%;
}

.form-row-last {
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #090f25;
    margin-bottom: 0 !important;
}

.login-img {
    margin-right: -83px;
    z-index: 3;
    margin-top: -8px;
    max-width: 245px;
}

.login-img2 {
    z-index: 1;
    max-width: 224px;
}

.login-img3 {
    max-width: 283px;
    margin-left: -97px;
    z-index: 0;
    margin-top: 33px;
}

/* mobile responsive */
@media only screen and (min-width: 767px) {
 
    .container {
        padding-left: 0px;
        padding-top: 20px;
    }

    .main-inner {
        flex-direction: column;
        display: flex;
    }

    .login-img,
    .login-img2 {
        max-width: 250px;
        height: 400px;
    }

    .login-img3 {
        max-width: 300px;
        height: 400px;
    }

    .login-image-row {
        padding-top: 20px;
    }
}

@media only screen and (min-width: 1040px) {

    .container {
        padding-left: 15px;
    }

    .main-inner {
        flex-direction: row;
        display: flex;
    }

    .login-img {
        max-width: 245px;
        height: auto;
    }

    .login-img2 {
        max-width: 224px;
        height: auto;
    }

    .login-img3 {
        max-width: 283px;
        height: auto;
    }

}
#backtologin {
    color: #a2370b;
    display: block;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13.1628px;
    line-height: 20px;
    margin-top: 6.5px;
    margin-bottom: -20px;
}
.account-details {
    display: flex;
    background-size: cover;
    background-position: center;
}

.left-account-detail {
    background: #fff;
    border: 1px solid #e4e5e7;
    border-radius: 12px;
    padding: 24px;
}

.left-side-detail {
    margin-bottom: 25px;
}

.account-det-heading {
    margin: 0;
    color: #525357;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: Roboto;
}

.account-det-text {
    color: #090f25;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-top: 3px;
}

.custom_dashboard_button, .update-shipping  {
    text-decoration: none;
    border: 1px solid #525357;
    border-radius: 6px;
    padding: 12px 20px;
    text-align: center;
    display: block;
    margin-top: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #525357;
    transition: .25s ease-in-out;
}
.custom_dashboard_button:hover, .update-shipping:hover{
    background-color: #525357;
    color: white;
}
.shipping-heading {
    color: #525357;
    letter-spacing: -.02em;
    text-transform: capitalize;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    margin: 0;
    font-size: 18px;
    line-height: 36px;
}

.billing-info {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #525357;
}

.left_inner_boxes {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.left-panel,
.right-panel {
    flex: 1;
    padding: 20px;
    margin: 0 10px;
}
hr {
    width: 100% !important;
}
.billing_shipping_information {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #525357;
    margin-top: 5px;
}
@media(max-width:768px){
    .right-account {
        display: none;
    }
    .account-login-title {
        font-size: 18px;
    }
}